// loginPage/Form.jsx
import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Snackbar,
  Alert,
  Slider,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const registerSchema = yup.object().shape({
  firstName: yup.string().required("Erforderlich"),
  lastName: yup.string().required("Erforderlich"),
  email: yup.string().email("Ungültige E-Mail").required("Erforderlich"),
  password: yup
    .string()
    .min(8, "Passwort muss mindestens 8 Zeichen lang sein")
    .required("Erforderlich"),
  picture: yup.mixed(),
});

const loginSchema = yup.object().shape({
  email: yup.string().email("Ungültige E-Mail").required("Erforderlich"),
  password: yup.string().required("Erforderlich"),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  picture: null,
};

const initialValuesLogin = {
  email: "",
  password: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"

  // Image cropping states
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const register = async (values, onSubmitProps) => {
    try {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("password", values.password);

      if (values.picture) {
        formData.append("picture", values.picture, "profile.jpg");
        formData.append("picturePath", "profile.jpg");
      } else {
        // If no picture, set default picture path
        formData.append("picturePath", "DefaultPFP.png");
      }

      const savedUserResponse = await fetch(`${BASE_URL}/auth/register`, {
        method: "POST",
        body: formData,
      });

      if (!savedUserResponse.ok) {
        const errorText = await savedUserResponse.text();
        throw new Error(errorText);
      }

      const savedUser = await savedUserResponse.json();
      onSubmitProps.resetForm();

      if (savedUser) {
        setSnackbarMessage(
          "Registrierung erfolgreich! Bitte loggen Sie sich ein."
        );
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setPageType("login");
      }
    } catch (err) {
      console.error("Registration failed:", err);
      setSnackbarMessage("Fehler bei der Registrierung: " + err.message);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const login = async (values, onSubmitProps) => {
    try {
      const loggedInResponse = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (!loggedInResponse.ok) {
        const errorText = await loggedInResponse.text();
        throw new Error(errorText);
      }

      const loggedIn = await loggedInResponse.json();
      onSubmitProps.resetForm();
      if (loggedIn) {
        dispatch(
          setLogin({
            user: loggedIn.user,
            token: loggedIn.token,
          })
        );
        navigate("/");
      }
    } catch (err) {
      console.error("Login failed:", err);
      setSnackbarMessage("Fehler beim Einloggen: " + err.message);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isLogin) await login(values, onSubmitProps);
    if (isRegister) await register(values, onSubmitProps);
  };

  // Move readFile function outside since it doesn't depend on Formik
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
        validationSchema={isLogin ? loginSchema : registerSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => {
          // Move onCropComplete inside Formik's render function
          const onCropComplete = async () => {
            try {
              const croppedImageBlob = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
              );
              setCroppedImage(croppedImageBlob);
              setFieldValue("picture", croppedImageBlob);
              setShowCropper(false);
            } catch (e) {
              console.error(e);
            }
          };

          // Move onFileChange inside Formik's render function
          const onFileChange = async (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
              const file = acceptedFiles[0];
              const imageDataUrl = await readFile(file);
              setImageSrc(imageDataUrl);
              setShowCropper(true);
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {isRegister && (
                  <>
                    <TextField
                      label="Vorname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      name="firstName"
                      error={
                        Boolean(touched.firstName) && Boolean(errors.firstName)
                      }
                      helperText={touched.firstName && errors.firstName}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      name="lastName"
                      error={
                        Boolean(touched.lastName) && Boolean(errors.lastName)
                      }
                      helperText={touched.lastName && errors.lastName}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <Box gridColumn="span 4">
                      <Typography variant="subtitle1" gutterBottom>
                        Profilbild hochladen
                      </Typography>
                      <Button variant="outlined" component="label">
                        Bild auswählen
                        <input
                          type="file"
                          hidden
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => onFileChange(e.target.files)}
                        />
                      </Button>
                      {croppedImage ? (
                        <Box
                          component="img"
                          src={URL.createObjectURL(croppedImage)}
                          alt="Cropped Image"
                          sx={{
                            width: 100,
                            height: 100,
                            borderRadius: "50%",
                            mt: 2,
                          }}
                        />
                      ) : (
                        <Typography mt={2}>
                          Kein Profilbild ausgewählt
                        </Typography>
                      )}
                    </Box>
                    {/* Cropper Modal */}
                    {showCropper && (
                      <Box className="cropper-container">
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          cropShape="round"
                          showGrid={false}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={(croppedArea, croppedAreaPixels) => {
                            setCroppedAreaPixels(croppedAreaPixels);
                          }}
                          maxZoom={3}
                        />
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          onChange={(e, zoom) => setZoom(zoom)}
                          sx={{ mt: 2 }}
                        />
                        <Button
                          variant="contained"
                          onClick={onCropComplete}
                          sx={{ mt: 2 }}
                        >
                          Zuschneiden
                        </Button>
                      </Box>
                    )}
                  </>
                )}

                {/* Email and Password Fields */}
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Passwort"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={
                    Boolean(touched.password) && Boolean(errors.password)
                  }
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>

              {/* BUTTONS */}
              <Box>
                <Button
                  fullWidth
                  type="submit"
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                    backgroundColor: palette.primary.main,
                    color: palette.background.alt,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  {isLogin ? "EINLOGGEN" : "REGISTRIEREN"}
                </Button>
                <Typography
                  onClick={() => {
                    setPageType(isLogin ? "register" : "login");
                    resetForm();
                  }}
                  sx={{
                    textDecoration: "underline",
                    color: palette.primary.main,
                    "&:hover": {
                      cursor: "pointer",
                      color: palette.primary.light,
                    },
                  }}
                >
                  {isLogin
                    ? "Kein Konto? Hier registrieren."
                    : "Schon ein Konto? Hier anmelden."}
                </Typography>
              </Box>
            </form>
          );
        }}
      </Formik>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Form;