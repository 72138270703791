// Impressum/index.jsx

import React from 'react';
import { Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';

const ImpressumPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:999px)');

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: isMobile ? '10px' : '20px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <Typography
          variant="h3" // Increased from h4 to h3
          gutterBottom
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '30px', // Increased spacing
          }}
        >
          Impressum
        </Typography>

        <Typography
          variant="h5" // Increased from h6 to h5
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }} // Added marginTop for spacing
        >
          Firmierung:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          MediConsult GmbH
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          Adresse:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          Cremon 11, 20257 Hamburg
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          Tel.: +49 (0) 1 72 / 3 21 59 78
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          E-Mail:{' '}
          <Link href="mailto:hoffmann@medizindialog.de">
            hoffmann@medizindialog.de
          </Link>
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          Inhaber:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
        Andreas Metz
        <br />
          Dr. Peter Hoffmann
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          Registernummer:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          HRB 207001
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          Registergericht:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          AG Lüneburg
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          USt.-ID:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          DE217241097
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          Vertretungsberechtigter:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
        Andreas Metz
        <br />
          Dr. Peter Hoffmann
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', marginTop: '30px' }}
        >
          Verbraucherinformationen
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit, die Sie hier finden:{' '}
          <Link href="https://ec.europa.eu/consumers/odr">
            ec.europa.eu/consumers/odr
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG)
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
          Wir nehmen an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle im Sinne des VSBG nicht teil und sind
          hierzu auch nicht verpflichtet.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
};

export default ImpressumPage;
