// PlaceholderPostsWidget.jsx

import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const PlaceholderPostsWidgetWettbewerb = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:600px)');

  // Define an array of placeholder images
  const placeholderImages = [
    '/assets/KK_Rohling_Muster1.jpg',
    '/assets/KK_Rohling_Muster2.jpg',
    '/assets/KK_Rohling_Muster3.jpg',
    '/assets/KK_Rohling_Muster4.jpg',
    '/assets/KK_Rohling_Muster5.jpg',
    '/assets/KK_Rohling_Muster6.jpg',
  ];

  // State for lightbox
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Prepare slides for the lightbox
  const slides = placeholderImages.map((image) => ({
    src: image,
    alt: 'Placeholder image',
  }));

  const handleOpenLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleCloseLightbox = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns={
          isNonMobileScreens ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'
        }
        gap="1rem"
      >
        {placeholderImages.map((imagePath, index) => (
          <Box
            key={index}
            position="relative"
            onClick={() => handleOpenLightbox(index)}
            sx={{
              width: '100%',
              overflow: 'hidden',
              cursor: 'pointer',
              '&:after': {
                content: '""',
                display: 'block',
                paddingBottom: '100%', // Maintains 1:1 aspect ratio
              },
            }}
          >
            <img
              src={imagePath}
              alt="placeholder"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        ))}
      </Box>

      {isOpen && (
        <Lightbox
          slides={slides}
          open={isOpen}
          index={photoIndex}
          close={handleCloseLightbox}
          styles={{
            container: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
            },
          }}
          on={{
            slideChange: ({ index }) => setPhotoIndex(index),
          }}
        />
      )}
    </>
  );
};

export default PlaceholderPostsWidgetWettbewerb;
