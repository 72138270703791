// PostWidget.jsx
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost, removePost } from "state";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  FavoriteBorderOutlined,
  Favorite,
  DeleteOutline,
  Close,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Modal,
} from "@mui/material";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const PostWidget = ({
  postId,
  postUserId,
  picturePath,
  likes,
  comments,
  allImages,
  index,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const loggedInUserId = user?._id;

  const isPostLiked = loggedInUserId ? Boolean(likes[loggedInUserId]) : false;
  const [isLiked, setIsLiked] = useState(isPostLiked);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(index);
  const [openDialog, setOpenDialog] = useState(false); // For delete confirmation
  const [loginModalOpen, setLoginModalOpen] = useState(false); // For login prompt

  const likeCount = Object.keys(likes).length;

  const patchLike = async () => {
    if (!loggedInUserId || !token) {
      setLoginModalOpen(true);
      return;
    }
    const response = await fetch(`${BASE_URL}/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
    setIsLiked(!isLiked);
  };

  const handleDeletePost = async () => {
    try {
      const response = await fetch(`${BASE_URL}/posts/${postId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      // Remove the post from the Redux store
      dispatch(removePost({ postId }));

      // Close the dialog and lightbox
      setOpenDialog(false);
      setIsOpen(false);
    } catch (err) {
      console.error("Failed to delete post:", err);
      // Handle error (e.g., show a notification)
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenLightbox = () => {
    setIsOpen(true);
  };
  const handleCloseLightbox = () => {
    setIsOpen(false);
  };

  // Prepare images for the lightbox
  const slides = allImages.map((image) => ({
    src: `${BASE_URL}/assets/${image.picturePath}`,
    alt: "Post image",
  }));

  const isUserPostOwner = loggedInUserId === postUserId;

  // Modal styles
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpenLightbox}
        sx={{
          width: "100%",
          overflow: "hidden",
          cursor: "pointer",
          "&:after": {
            content: '""',
            display: "block",
            paddingBottom: "100%", // Maintains 1:1 aspect ratio
          },
        }}
      >
        {picturePath && (
          <img
            src={`${BASE_URL}/assets/${picturePath}`}
            alt="post"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        )}
        {isHovered && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent modal from opening when like button is clicked
                  patchLike();
                }}
                sx={{
                  color: "white",
                }}
              >
                {isLiked ? <Favorite /> : <FavoriteBorderOutlined />}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </Box>
          </Box>
        )}
        {isUserPostOwner && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenDialog(true);
            }}
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
            }}
          >
            <DeleteOutline />
          </IconButton>
        )}
      </Box>

      {isOpen && (
        <Lightbox
          slides={slides}
          open={isOpen}
          index={photoIndex}
          close={handleCloseLightbox}
          render={{
            buttonPrev: null, // Hide the previous button if needed
            buttonNext: null, // Hide the next button if needed
          }}
          styles={{
            container: {
              backgroundColor: "rgba(0, 0, 0, 0.9)",
            },
          }}
          on={{
            slideChange: ({ index }) => setPhotoIndex(index),
          }}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Beitrag löschen</DialogTitle>
        <DialogContent>
          <Typography>Möchten Sie diesen Beitrag wirklich löschen?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Abbrechen</Button>
          <Button onClick={handleDeletePost} color="error">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Login Modal */}
      <Modal
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        disableScrollLock={true} // Added this line
      >
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setLoginModalOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h6" align="center" gutterBottom>
          Sie müssen eingeloggt sein, um Posts zu liken.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = "/einloggen";
              }}
            >
              Einloggen
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PostWidget;
