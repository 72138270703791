// EventsPage.jsx

import React, { useState } from 'react';
import { Box, useMediaQuery, Typography, Button, useTheme, Link, List, ListItem, Divider } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import Posts from 'scenes/widgets/PlaceholderPostsWidget.jsx'
import { Link as RouterLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Countdown from 'react-countdown';
import KKM_SVG from 'scenes/widgets/KKM_SVG.jsx';

const EventsPage = () => {
  const theme = useTheme();
  const [sectionIndex, setSectionIndex] = useState(0);
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');

  const handleShowMore = () => {
    setSectionIndex((prevIndex) => prevIndex + 1);
  };

  const sections = [
//     {
//       title: 'Unsere Events:',
//       content: `
// **Januar 2025:**

// **Kunst, Kaffee & Mehr – Kick-Off Event**

// **Samstag, 11. Januar 2025, 11 Uhr**  
// **Kunst-Kaleidoskop-Zentrale, Cremon 11, 20457 Hamburg**

// Willkommen zum ersten Kunst, Kaffee & Mehr-Event!

// Erleben Sie einen einzigartigen Tag voller Kunst, Kulinarik und Kultur. Unser Kick-Off-Event steht ganz im Zeichen Bulgariens und bietet Ihnen eine außergewöhnliche Gelegenheit, das Land und seine Highlights zu entdecken.

// **Was erwartet Sie?**

// - **Thema: Bulgarien**  
//   Tauchen Sie ein in die Vielfalt Bulgariens – von Kunst und Kulinarik bis hin zu hochinteressanten Investitionsmöglichkeiten für das Projekt InvestBulgaria.org.

// - **Kulinarische Highlights:**  
//   Genießen Sie bulgarische Spezialitäten wie Shopska-Salat und erlesene bulgarische Weine. Ab 12 Uhr erwartet Sie ein Brunch mit warmen und kalten Köstlichkeiten, der Ihren Gaumen verwöhnt.

// - **Kunst von Peter Robert Keil („Der Bürgerliche“):**  
//   Ab 12 Uhr können Sie die Werke von Peter Robert Keil, auch bekannt als „Picasso 2.0“, bewundern. Lassen Sie sich von seinen einzigartigen Kunstwerken inspirieren und bereiten Sie sich auf die Sonderversteigerung um 15 Uhr vor.

// - **Bewertung und Versteigerung der Top Ten Kunstkaleidoskope:**  
//   Ab 12 Uhr beginnt die Bewertung der eingereichten Kunstkaleidoskope. Um 16 Uhr werden die Top Ten Kunstkaleidoskope des Wettbewerbs präsentiert und versteigert. Sichern Sie sich ein einzigartiges Stück Kunst und fördern Sie gleichzeitig junge Talente!

// **Ihr Vorteil: Kunstkaleidoskop-Paket für 99 € netto**

// Werden Sie aktiv Teil des Kunstkaleidoskop-Projekts! Für nur 99 € netto erhalten Sie ein Paket mit 10 Rohlingen, die wir direkt an einen Ort Ihrer Wahl im Großraum Hamburg liefern. Ob als Geschenk, Förderung einer Einrichtung oder für Ihr eigenes Engagement – die Teilnahme war nie einfacher.

// **Agenda des Tages**

// - **11:00 Uhr: Beginn der Veranstaltung**  
//   Willkommen, Eröffnungsrede und Einführung in das Thema Bulgarien.

// - **12:00 Uhr: Brunch & Start der Kunstbewertungen**  
//   Genießen Sie warme und kalte bulgarische Spezialitäten. Zeitgleich können Sie die Werke von Peter Robert Keil bewundern und die Bewertung der Kunstkaleidoskope beginnt.

// - **15:00 Uhr: Sonderversteigerung**  
//   Exklusive Kunstwerke von Peter Robert Keil kommen unter den Hammer.

// - **16:00 Uhr: Bewertung und Versteigerung der Top Ten Kunstkaleidoskope**  
//   Erleben Sie die besten Kunstwerke des Wettbewerbs und bieten Sie mit.

// - **18:00 Uhr: Ende der Veranstaltung**

// **Entdecken, genießen, erleben**

// Besuchen Sie uns am Samstag, den 11. Januar 2025, in der Kunst-Kaleidoskop-Zentrale und lassen Sie sich von der Vielfalt Bulgariens inspirieren. Ob Kunstliebhaber, Kulinarik-Fan oder Investitionsinteressierter – dieses Event bietet für jeden etwas Besonderes.

// Wir freuen uns auf Ihren Besuch!
//       `,
//     },
{
  title: '',
  content: ``,
},
    {
      title: 'Kunst, Kaffee & Mehr – Die Themen-Events des Kunstkaleidoskop-Projekts',
      content: `
Unsere Kunst, Kaffee & Mehr-Events sind wöchentliche Themenevents, die Kunst, Bildung und Wirtschaft in einem einzigartigen Rahmen vereinen. Jedes Event widmet sich einem bestimmten Thema, das die Inhalte und den Charakter der Veranstaltung prägt. Von Sport über Kulinarik bis hin zu Kunstworkshops – diese Events bieten für alle Beteiligten wertvolle Möglichkeiten und Vorteile.
      `,
    },
    {
      title: 'Start der Themen-Events 2025',
      content: `
Im Jahr 2025 beginnt die Veranstaltungsreihe Kunst, Kaffee & Mehr offiziell. Wir fokussieren uns zunächst auf Partnerschaften mit Gastronomiebetrieben und Event-Locations wie Restaurants und Cafés, um diese Standorte zu nutzen und Zeiten zu füllen, die oft als „tote Zeiten“ gelten. Durch die Events können Gastronomen und Eventbetreiber so zusätzliche Gäste anziehen und ihr Haus zu weniger frequentierten Zeiten beleben.

Unternehmen können sich jetzt bewerben und sich ihre bevorzugten Kalenderwochen für 2025 sichern, in denen ihr Event exklusiv stattfindet – pro Kalenderwoche wird maximal ein Termin vergeben. Einige Wochen sind bereits vorreserviert, doch in der Startphase bieten wir die gesamte Vertriebspower unserer Agenten kostenfrei an. Der erste Samstag im Monat ist bereits festgelegt: An diesem Tag findet unser eigenes „Kunst, Kaffee und Mehr“-Event immer in der Kunstkaleidoskop-Galerie statt. Die weiteren Wochen können frei belegt werden.
      `,
    },
    {
      title: 'Unterstützung durch unsere Agenten und Partner',
      content: `
Dank der Kooperation mit den Snookerfy-Agenten steht unseren ersten Partnern ein umfassendes Support-Team zur Verfügung. In der Startphase stellen wir unsere Snookerfy-Agenten den Veranstaltern kostenfrei bereit – vom Marketing über die Organisation bis hin zur Durchführung übernehmen die Agenten die gesamte Eventbegleitung. So wird es für Unternehmen einfach, ein Kunst, Kaffee & Mehr-Event zu gestalten und von unserer Expertise zu profitieren.

Unser erster Gastronomie-Partner ist das Wave City in der HafenCity, das 2025 als erste Eventlocation unser Konzept umsetzen wird und alle Dienstleistungen der Snookerfy-Agenten kostenfrei in Anspruch nehmen kann. Diese exklusive Möglichkeit bietet unseren ersten Partnern maximale Unterstützung und sichert ihnen die komplette Begleitung ihrer Veranstaltung.

Bald wird es auf unserer Webseite eine Übersicht aller geplanten Events geben, in der Sie auf einen Blick sehen, welche Woche bereits belegt ist und welche Wochen noch verfügbar sind.
      `,
    },
    {
      title: 'Vorteile der Themen-Events für alle Beteiligten',
      content: `
**Für Veranstalter: Eine starke Plattform zur Sichtbarkeit und Kundenbindung**

Als Veranstalter eines Kunst, Kaffee & Mehr-Events haben Sie die Möglichkeit, Ihr Unternehmen und Ihre Angebote einem neuen Publikum vorzustellen und dabei das Eventthema frei zu wählen. Unsere Agenten kümmern sich um alle organisatorischen Details, damit Sie sich auf die Gestaltung und Ausführung des Programms konzentrieren können. Das Event zieht neue Gäste an und belebt Ihre Räume in weniger frequentierten Zeiten – eine ideale Gelegenheit, Ihre Marke zu stärken und neue Kunden zu gewinnen.

Ihre Vorteile im Überblick:

- **Maximale Flexibilität:** Bestimmen Sie das Thema und den Charakter Ihres Events.
- **Komplette Organisation:** Dank der Unterstützung unserer Snookerfy-Agenten übernehmen wir die Planung und Durchführung des Events.
- **Erweiterte Reichweite:** Profitieren Sie von unserer Plattform, die Ihr Event bewirbt und neue Gäste anzieht.
- **Exklusivität:** Pro Woche wird nur ein Event vergeben, und unser Marketing unterstützt Sie kostenfrei.

**Für Schüler: Eine kreative Bühne und wertvolle Erfahrungen**

Die Kunst, Kaffee & Mehr-Events bieten den teilnehmenden Schülern eine inspirierende Plattform, um ihre Kunstkaleidoskope vor Ort zu präsentieren. Neben der Wertschätzung ihrer Werke erhalten sie durch das Event eine Dividende, die aus Spenden des Abends finanziert wird und unter allen anwesenden Schülern fair verteilt wird. Darüber hinaus können sie erste Kontakte zur Wirtschaft knüpfen und Einblicke in die kreative und unternehmerische Welt gewinnen.

Ihre Vorteile im Überblick:

- **Bühne für ihre Kunst:** Schüler präsentieren ihre Kunstkaleidoskope und erhalten direkte Anerkennung.
- **Finanzielle Wertschätzung:** Eine Dividende aus Spendengeldern honoriert ihre Teilnahme.
- **Erfahrung und Netzwerke:** Die Schüler sammeln wertvolle Erfahrungen und knüpfen erste Kontakte zu Unternehmen und Förderern.
- **Interaktive Themenwelten:** Durch das Eventthema können die Schüler neue Erfahrungen sammeln, sei es durch sportliche Aktivitäten, kreative Workshops oder Verkostungen.

**Für Unternehmen und Werbepartner: Exklusive Sichtbarkeit und Kundengewinnung**

Unternehmen und Werbepartner können bei Kunst, Kaffee & Mehr-Events ihre Produkte und Dienstleistungen in einem kreativen, thematischen Umfeld präsentieren. Diese einzigartige Plattform bietet exklusive Präsenz bei einem kunstinteressierten Publikum und gibt Ihnen die Möglichkeit, direkt mit potenziellen Kunden und zukünftigen Fachkräften in Kontakt zu treten. Sie können exklusive Rabatte und Angebote für Wochensieger-Kaleidoskope schaffen, die auf der Anzahl der gesammelten Likes basieren, und so für Ihre Marke nachhaltig Aufmerksamkeit erzeugen.

Ihre Vorteile im Überblick:

- **Gezielte Markenpräsenz:** Präsentieren Sie Ihre Marke, Produkte und Dienstleistungen in einem inspirierten Rahmen.
- **Exklusiver Zugang zur Zielgruppe:** Schulen, Schüler und kunstinteressierte Gäste sind vor Ort – ideal, um neue Kunden und künftige Fachkräfte zu erreichen.
- **Individuelle Kundenangebote:** Unternehmen können spezielle Vorteile und Rabatte für Kaleidoskop-Wochensieger bieten und damit Kundentreue aufbauen.
      `,
    },
    {
      title: 'Persönliche Beratung vor Ort',
      content: `
Um alle weiteren Informationen und Details zur Teilnahme an den Kunst, Kaffee & Mehr-Events zu erhalten, stehen wir Ihnen gerne zur Verfügung. Besuchen Sie uns in der Kunstkaleidoskop-Galerie, jeden Freitag zwischen 10 und 16 Uhr, um sich vor Ort über das Projekt und Ihre Teilnahme als Veranstalter oder Partner zu informieren.
      `,
    },
  ];

  const currentSections = sections.slice(0, sectionIndex + 1);

    // Countdown renderer
    const countdownRenderer = ({ days, hours, minutes, seconds }) => {
      return (
        <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
          {days} Tage {hours} Stunden {minutes} Minuten {seconds} Sekunden
        </Typography>
      );
    };
  
    // Target date: January 1st, 2025, at 00:00 Uhr
    const targetDate = new Date('2025-01-11T00:00:00');

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: '20px',
          maxWidth: '800px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom>
          Diese Seite befindet sich derzeit im Aufbau.
        </Typography>

                {/* Countdown */}
                <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
          Countdown zum Start der Events
          </Typography>
          <Countdown date={targetDate} renderer={countdownRenderer} />
        </Box>
      </Box>

      <Box
  sx={{
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '20px',
  }}
>
<Typography variant="h2" align="center" sx={{ fontWeight: 'bold', mb: '1rem' }}>
          Kick-Off Event
          </Typography>
          <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: '2rem' }}>
          Samstag, 11. Januar 2025, 11 Uhr
          </Typography>
<KKM_SVG />
          <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/werbepartner"
                  sx={{
                    marginBottom: '20px',
                    paddingX: '16px',
                    paddingY: '8px',
                    fontSize: '1rem',
                  }}
                >
                  Jetzt Tickets sichern
                </Button>
                  </Box>
                          {/* Themen Section */}
        <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', mt: '2rem', mb: '1rem' }}>
          Themen
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: '1rem', mb:'2rem' }}>
          <Box sx={{ flex: 1, textAlign: 'center', pr: '1rem' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mb: '0.5rem' }}>
              Bulgarien
            </Typography>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
              Entdecken. Kaufen. Entwickeln.
            </Typography>
            <Button variant="contained" color="primary" href="http://www.investbulgaria.org" target="_blank">
              Mehr Info
            </Button>
          </Box>
        {/* Vertical Divider */}
        <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1, textAlign: 'center', pl: '1rem' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mb: '0.5rem' }}>
              Peter Robert Keil
            </Typography>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
            Picasso 2.0 und „die neuen Wilden“
            </Typography>
            <Button variant="contained" color="primary" href="https://www.keil-collection-heidelberg.de/p-r-keil/" target="_blank">
              Mehr Info
            </Button>
          </Box>
        </Box>

        <Box
        sx={{
          padding: '20px',
          maxWidth: '1200px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
          fontSize: '1rem'
        }}
      >
        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: '1rem' }}>
        Kunstkaleidoskop Galerie, Cremon 11, 20457 Hamburg
        </Typography>
      <Divider sx={{ my: '2rem' }} />
        <Typography variant="h3" align="left" sx={{ fontWeight: 'bold', mb: '1rem' }}>
          Willkommen zum ersten Kunst, Kaffee & Mehr-Event!
        </Typography>

        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          Erleben Sie Kunst, Kulinarik und Kultur an einem außergewöhnlichen Tag. Unser Kick-Off-Event widmet sich der Vielfalt Bulgariens und gibt Ihnen die Möglichkeit, das Land, seine Spezialitäten und spannende Investitionsmöglichkeiten zu entdecken.
        </Typography>
        <Divider sx={{ my: '2rem' }} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', mt: '1rem', mb: '1rem' }}>
          Exklusives Startangebot – Nur für Unternehmer
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          Das <strong>Eventticket</strong> ist ausschließlich für Unternehmer buchbar und kostet <strong>99 € netto pro Person</strong>.
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem', marginBottom:'0.25rem'}}>
          Darin enthalten:
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              <strong>10 Kunstkaleidoskop-Rohlinge:</strong> Unser Startangebot für zukünftige Werbepartner oder ein kreatives Weihnachtsgeschenk für soziale Einrichtungen Ihrer Wahl wo Ihnen die Kinder besonders am Herzen liegen, <strong>frei Haus geliefert</strong> im Großraum Hamburg.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              <strong>Fördern Sie soziale Projekte:</strong> Die Rohlinge können von teilnehmenden Kindern bis zum <strong>24.12.2024</strong> gestaltet und digital eingereicht werden (max. 5 Bilder und ein Video, max. 15 Sekunden Länge) an <Link href="mailto:info@kunstkaleidoskop-hamburg.de">info@kunstkaleidoskop-hamburg.de</Link> oder auf unserer Webseite.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="body1" paragraph sx={{fontSize:'1rem', marginBottom:'0.25rem'}}>
          <strong>Exklusiver Vorteil für Kinder, die teilnehmen:</strong>
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              <strong>Top 10 Kunstkaleidoskope:</strong><br />Jedes Kind der besten 10 erhält einen <strong>25 € Amazon-Gutschein</strong>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0,}}>
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              <strong>Top 3 Kunstkaleidoskope:</strong><br /> Die drei besten Einreichungen gewinnen je <strong>2 Tickets für den 11. Januar</strong>, zusätzlich für das jeweilige Schulkind.
            </Typography>
          </ListItem>
        </List>
        <Divider sx={{ my: '2rem' }} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', mt: '1rem', mb: '1rem' }}>
          Was erwartet Sie beim Event?
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          <strong>Thema: Bulgarien</strong>
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          Erleben Sie die kulturelle und kulinarische Vielfalt Bulgariens. Lassen Sie sich inspirieren und entdecken Sie die hochinteressanten <strong>Investitionsmöglichkeiten</strong> für das Projekt <strong><Link href="http://www.investbulgaria.org">InvestBulgaria.org</Link></strong>.
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          <strong>Kulinarische Highlights:</strong><br />Probieren Sie bulgarische Spezialitäten wie z.B. <strong>Shopska-Salat</strong> und erlesene Weine. Ab <strong>11 Uhr</strong> lädt ein Brunch mit warmen und kalten Köstlichkeiten zum Genießen ein.
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          <strong>Kunst von Peter Robert Keil („Für Jedermann“):</strong><br />Ab 12 Uhr präsentieren wir die Werke von Peter Robert Keil, bekannt als „Picasso 2.0“. Seine einzigartigen Kunstwerke stehen um <strong>15 Uhr</strong> im Mittelpunkt einer Sonderversteigerung.
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          <strong>Bewertung und Versteigerung der Top Ten Kunstkaleidoskope:</strong><br />Ab 12 Uhr beginnt auch die <strong>Bewertung der digital eingereichten Kunstkaleidoskope</strong>. Um 16 Uhr erleben Sie die Top 3 Kunstkaleidoskope, die live versteigert werden. Unterstützen Sie kreative Talente und sichern Sie sich ein einzigartiges Kunstwerk!
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          Unterstützen Sie uns dabei, Schulkinder aus der digitalen Welt ins Leben zu holen.
        </Typography>
        <Divider sx={{ my: '2rem'}} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', mt: '1rem', mb: '1rem' }}>
          Agenda des Tages
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
              <strong>11:00 Uhr:</strong> Beginn der Veranstaltung<br />Eröffnung und Einführung in das Thema Bulgarien.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
              <strong>12:00 Uhr:</strong> Kunstbewertungen<br />Genießen Sie dazu warme und kalte Spezialitäten und begutachten Sie die Werke von Peter Robert Keil, bewundern Sie die TOP 3 Kunstwerke analog und bewerten Sie digital.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
              <strong>15:00 Uhr:</strong> Sonderversteigerung<br />Drei Kunstwerke von Peter Robert Keil werden versteigert.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0, paddingBottom:'0.5rem' }}>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
              <strong>16:00 Uhr:</strong> Auswertung und Versteigerung der Top 3 Kunstkaleidoskope<br />Erleben Sie die besten Werke des Wettbewerbs und bieten Sie mit.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0,}}>
            <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
              <strong>18:00 Uhr:</strong> Veranstaltungsende
            </Typography>
          </ListItem>
        </List>
        <Divider sx={{ my: '2rem' }} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', mt: '1rem', mb: '1rem' }}>
          Jetzt Ihr Ticket sichern!
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          Seien Sie Teil eines exklusiven Events und unterstützen Sie kreative Projekte mit Ihrem Beitrag. Buchen Sie jetzt Ihr Ticket und sichern Sie sich zusätzlich Ihre 10 Rohlinge für soziale Einrichtungen.
        </Typography>
        <Typography variant="body1" paragraph sx={{fontSize:'1rem'}}>
          Wir freuen uns auf Ihren Besuch!
        </Typography>
        </Box>
                  

  {currentSections.map((section, index) => (
    <Box key={index} sx={{ marginBottom: '20px' }}>
      {section.title && (
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
          {section.title}
        </Typography>
      )}
      {section.content && (
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <Typography variant="body1" sx={{ fontSize: '1rem' }} paragraph {...props} />
            ),
            strong: ({ node, ...props }) => <strong {...props} />,
            em: ({ node, ...props }) => <em {...props} />,
            ul: ({ node, ...props }) => (
              <List
                sx={{
                  paddingLeft: '1em',
                  marginTop: 0,
                  marginBottom: 0,
                }}
                {...props}
              />
            ),
            li: ({ node, ...props }) => (
              <ListItem
                sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}
                disableGutters
                disablePadding
              >
                <Typography variant="body1" sx={{ fontSize: '1rem' }} component="span" {...props} />
              </ListItem>
            ),
          }}
        >
          {section.content}
        </ReactMarkdown>
      )}
      {index < sections.length - 1 && index === sectionIndex && (
        <Box textAlign="center">
          <Button variant="text" onClick={handleShowMore} sx={{ marginTop: '10px' }}>
            Mehr erfahren
          </Button>
        </Box>
      )}
    </Box>
  ))}
</Box>

      <Box
        width="100%"
        padding="0rem 6%"
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap="0.5rem"
        justifyContent="center">
                  <Box
          flexBasis={isNonMobileScreens ? '75%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
        >
              <Posts></Posts>
              </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default EventsPage;
