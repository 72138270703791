// kontoPage/KontoForm.jsx
import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Snackbar,
  Alert,
  Slider,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { setLogin } from "state";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const updateSchema = yup.object().shape({
  firstName: yup.string().required("Erforderlich"),
  lastName: yup.string().required("Erforderlich"),
  picture: yup.mixed(),
});

const KontoForm = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const { palette } = useTheme();
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  // Image cropping states
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  // Initialize form values
  const initialValuesUpdate = {
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    picture: null,
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);

      if (values.picture) {
        formData.append("picture", values.picture, "profile.jpg");
        formData.append("picturePath", "profile.jpg");
      }

      const response = await fetch(`${BASE_URL}/users/${user._id}/update`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      const data = await response.json();

      if (data) {
        dispatch(
          setLogin({
            user: data,
            token: token,
          })
        );

        // Show success message
        setSnackbarMessage("Profil erfolgreich aktualisiert.");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      }

      // Reset the picture field
      onSubmitProps.setFieldValue("picture", "");
      setCroppedImage(null);
    } catch (err) {
      console.error("Update failed:", err.message);
      // Show error message
      setSnackbarMessage("Fehler beim Aktualisieren des Profils.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // Function to read the file
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesUpdate}
        validationSchema={updateSchema}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          // Function to handle crop completion
          const onCropComplete = async () => {
            try {
              const croppedImageBlob = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
              );
              setCroppedImage(croppedImageBlob);
              setFieldValue("picture", croppedImageBlob);
              setShowCropper(false);
            } catch (e) {
              console.error(e);
            }
          };

          // Function to handle file selection
          const onFileChange = async (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
              const file = acceptedFiles[0];
              const imageDataUrl = await readFile(file);
              setImageSrc(imageDataUrl);
              setShowCropper(true);
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  label="Vorname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={
                    Boolean(touched.firstName) && Boolean(errors.firstName)
                  }
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: "span 2" }}
                />

                {/* Profile Picture Upload and Cropping */}
                <Box gridColumn="span 4">
                  <Typography variant="subtitle1" gutterBottom>
                    Profilbild ändern
                  </Typography>
                  <Button variant="outlined" component="label">
                    Bild auswählen
                    <input
                      type="file"
                      hidden
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => onFileChange(e.target.files)}
                    />
                  </Button>
                  {croppedImage ? (
                    <Box
                      component="img"
                      src={URL.createObjectURL(croppedImage)}
                      alt="Cropped Image"
                      sx={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        mt: 2,
                      }}
                    />
                  ) : (
                    <Box mt={2}>
                      {user.picturePath ? (
                        <Box
                          component="img"
                          src={`${BASE_URL}/assets/${user.picturePath}`}
                          alt="Current Profile"
                          sx={{
                            width: 100,
                            height: 100,
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Typography>Kein Profilbild ausgewählt</Typography>
                      )}
                    </Box>
                  )}
                </Box>

                {/* Cropper Modal */}
                {showCropper && (
                  <Box className="cropper-container" sx={{ position: "relative", height: 400, width: "100%" }}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      cropShape="round"
                      showGrid={false}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={(croppedArea, croppedAreaPixels) => {
                        setCroppedAreaPixels(croppedAreaPixels);
                      }}
                      maxZoom={3}
                    />
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      onChange={(e, zoom) => setZoom(zoom)}
                      sx={{ mt: 2 }}
                    />
                    <Button
                      variant="contained"
                      onClick={onCropComplete}
                      sx={{ mt: 2 }}
                    >
                      Zuschneiden
                    </Button>
                  </Box>
                )}
              </Box>

              {/* Submit Button */}
              <Box>
                <Button
                  fullWidth
                  type="submit"
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                    backgroundColor: palette.primary.main,
                    color: palette.background.alt,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  Aktualisieren
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default KontoForm;
