// DatenschutzPage.jsx
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';

const DatenschutzPage = () => {
  const theme = useTheme();

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: '20px',
          maxWidth: '800px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom>
        Diese Seite befindet sich derzeit im Aufbau.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
};

export default DatenschutzPage;
