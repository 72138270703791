// PostsWidget.jsx

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import PostWidget from "./PostWidget";
import { Box, Typography, useMediaQuery } from "@mui/material";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const PostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts) || [];
  const token = useSelector((state) => state.token);

  const isNonMobileScreens = useMediaQuery("(min-width:600px)");

  const getPosts = async () => {
    try {
      const headers = token
        ? { Authorization: `Bearer ${token}` }
        : {};

      const response = await fetch(`${BASE_URL}/posts`, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        console.error("Failed to fetch posts:", response.statusText);
        return;
      }

      const data = await response.json();
      dispatch(setPosts({ posts: data }));
    } catch (error) {
      console.error("An error occurred while fetching posts:", error);
    }
  };

  const getUserPosts = async () => {
    if (!token || !userId) {
      // Can't fetch user posts without authentication
      return;
    }
    try {
      const response = await fetch(
        `${BASE_URL}/posts/${userId}/posts`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch user posts:", response.statusText);
        return;
      }

      const data = await response.json();
      dispatch(setPosts({ posts: data }));
    } catch (error) {
      console.error("An error occurred while fetching user posts:", error);
    }
  };

  useEffect(() => {
    if (isProfile) {
      getUserPosts();
    } else {
      getPosts();
    }
  }, [isProfile, userId]); // Added dependencies

  return (
    <Box
      display="grid"
      gridTemplateColumns={
        isNonMobileScreens ? "repeat(3, 1fr)" : "repeat(1, 1fr)"
      }
      gap="1rem"
    >
      {Array.isArray(posts) && posts.length > 0 ? (
        posts.map(
          (
            {
              _id,
              userId,
              firstName,
              lastName,
              description,
              location,
              picturePath,
              userPicturePath,
              likes,
              comments,
            },
            index
          ) => (
            <PostWidget
              key={_id}
              postId={_id}
              postUserId={userId}
              picturePath={picturePath}
              likes={likes}
              comments={comments}
              allImages={posts} // Pass the array of posts
              index={index}     // Pass the current index
            />
          )
        )
      ) : (
        <Typography>Keine Posts verfügbar.</Typography>
      )}
    </Box>
  );
};

export default PostsWidget;
