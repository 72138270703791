// footer/index.jsx
import {
    Box,
    Typography,
    Link,
    useTheme,
    useMediaQuery,
    IconButton,
    Divider,
  } from "@mui/material";
  import {
    Email,
    Phone,
    YouTube,
    Facebook,
    Instagram,
    LocationOn,
    AccessTime,
  } from "@mui/icons-material";
  import { useNavigate, Link as RouterLink } from "react-router-dom";

  
  const Footer = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
    const alt = theme.palette.background.alt;
    const navigate = useNavigate();
  
    return (
      <Box
        component="footer"
        width="100%"
        backgroundColor={alt}
        padding="2rem 6%"
        mt="2rem"
      >
        {/* Main Content Area */}
        <Box
          display="flex"
          flexDirection={isNonMobileScreens ? "row" : "column"}
          justifyContent="space-between"
          alignItems={isNonMobileScreens ? "flex-start" : "center"}
          textAlign={isNonMobileScreens ? "left" : "center"}
          gap="2rem"
        >
          {/* Left Column: Company Information */}
          <Box flexBasis="45%">
            <Typography variant="h6" gutterBottom>
              Kunstkaleidoskop
            </Typography>
            <Box display="flex" justifyContent={isNonMobileScreens ? "flex-start" : "center"} alignItems="center" gap="0.5rem">
              <LocationOn />
              <Typography>
              Andreas Metz
              <br />
                Dr. Peter Hoffmann
                <br />
                Cremon 11
                <br />
                20457 Hamburg
              </Typography>
            </Box>
  
            {/* Opening Hours */}
            <Box mt="1rem">
              <Typography variant="h6" gutterBottom>
                Öffnungszeiten
              </Typography>
              <Box display="flex" alignItems="center" gap="0.5rem">
                <AccessTime />
                <Typography>
                  Mo – Fr: 10:00 Uhr – 16:00 Uhr
                  <br />
                  Termine nach Vereinbarung, auch am Wochenende.
                </Typography>
              </Box>
            </Box>
          </Box>
  
          {/* Right Column: Contact Information and Social Media Icons */}
          <Box
            flexBasis="45%"
            display="flex"
            flexDirection="column"
            alignItems={isNonMobileScreens ? "flex-end" : "center"}
            textAlign={isNonMobileScreens ? "right" : "center"}
            gap="1rem"
          >
            {/* Contact Heading */}
            <Typography variant="h6" gutterBottom>
            <Link
              component={RouterLink}
              to="/kontakt"
              color="inherit"
              underline="hover"
            >
          Kontakt
        </Link>
      </Typography>
            {/* Email */}
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Email />
              <Link
                href="mailto:info@kunstkaleidoskop-hamburg.de"
                color="inherit"
                underline="hover"
              >
                info@kunstkaleidoskop-hamburg.de
              </Link>
            </Box>
            {/* Phone */}
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Phone />
              <Link
                href="tel:+4916099764318"
                color="inherit"
                underline="hover"
              >
                +49 (0) 160 99764318
              </Link>
            </Box>
            {/* Social Media Icons */}
            <Box display="flex" gap="1rem">
              <IconButton
                component="a"
                href="https://www.youtube.com/@Kunstkaleidoskop"
                target="_blank"
                rel="noopener"
              >
                <YouTube />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.instagram.com/kunstkaleidoskop/"
                target="_blank"
                rel="noopener"
              >
                <Instagram />
              </IconButton>
            </Box>
          </Box>
        </Box>
  
        {/* Bottom Area: Links with Dividers and Copyright */}
        <Box
          mt="2rem"
          display="flex"
          flexDirection={isNonMobileScreens ? "row" : "column"}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          gap="1rem"
        >
          {/* Links with Dividers */}
          <Box display="flex" alignItems="center" gap="1rem">
            <Link
              component={RouterLink}
              to="/datenschutz"
              color="inherit"
              underline="hover"
            >
              Datenschutz
            </Link>
            <Divider orientation="vertical" flexItem />
            <Link
              component={RouterLink}
              to="/impressum"
              color="inherit"
              underline="hover"
            >
              Impressum
            </Link>
            <Divider orientation="vertical" flexItem />
            <Link
              component={RouterLink}
              to="/agb"
              color="inherit"
              underline="hover"
            >
              AGB
            </Link>
          </Box>
        </Box>
  
        {/* Copyright */}
        <Box mt="1rem" textAlign="center">
          <Typography variant="body2" color="textSecondary">
            © 2024 MediConsult GmbH. Alle Rechte vorbehalten.
          </Typography>
        </Box>
      </Box>
    );
  };
  
  export default Footer;
  