// kontoPage/index.jsx
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Navbar from "scenes/navbar";
import KontoForm from "./KontoForm";

const KontoPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display="flex"
        justifyContent="center"
      >
        <Box
          width={isNonMobileScreens ? "50%" : "93%"}
          p="2rem"
          m="2rem 0"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
        >
          <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
            Konto bearbeiten
          </Typography>
          <KontoForm />
        </Box>
      </Box>
    </Box>
  );
};

export default KontoPage;
