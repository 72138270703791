// MyPostWidget.jsx

import {
  ImageOutlined,
  DeleteOutlined,
  Close,
  HelpOutline,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  useTheme,
  Button,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Dropzone from "react-dropzone";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const MyPostWidget = ({ picturePath }) => {
  const dispatch = useDispatch();
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(null);
  const [post, setPost] = useState("");
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false); // New state for info modal
  const { palette } = useTheme();
  const user = useSelector((state) => state.user);
  const _id = user?._id;
  const token = useSelector((state) => state.token);
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  const handlePost = async () => {
    if (!_id || !token) {
      setLoginModalOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append("userId", _id);
    if (image) {
      formData.append("picture", image);
      formData.append("picturePath", image.name);
    }

    const response = await fetch(`${BASE_URL}/posts`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const posts = await response.json();
    dispatch(setPosts({ posts }));
    setImage(null);
    setPost("");
  };

  return (
    <WidgetWrapper mb="1.5rem">
      <FlexBetween>
        <FlexBetween gap="0.25rem">
          <IconButton
            onClick={() => {
              if (!_id) {
                setLoginModalOpen(true);
              } else {
                setIsImage(!isImage);
              }
            }}
          >
            <ImageOutlined sx={{ color: mediumMain }} />
          </IconButton>
          <Button
            onClick={() => {
              if (!_id) {
                setLoginModalOpen(true);
              } else {
                setIsImage(!isImage);
              }
            }}
            sx={{
              color: mediumMain,
              textTransform: 'none', // Preserves the original text casing
              padding: 0,
              minWidth: 'auto', // Removes default minWidth to fit the content
              backgroundColor: 'transparent', // Makes background transparent
              '&:hover': {
                backgroundColor: 'transparent', // Prevents background color change on hover
                cursor: 'pointer',
                color: medium,
              },
            }}
          >
            Eigenes Bild hochladen
          </Button>
        </FlexBetween>

            <Box>
            <Button
          disabled={!image || !_id}
          onClick={handlePost}
          sx={{
            color: 'white',
            backgroundColor: palette.primary.main,
            borderRadius: "3rem",
            opacity: !(!image || !_id) ? 1 : 0.7,
            transition: 'opacity 0.3s ease',
            '&.Mui-disabled': {
              color: 'white',
              opacity: 0.7
            },
            '&:hover': {
              backgroundColor: palette.primary.dark,
            },
          }}
        >
          POST
        </Button>
                  {/* Question Mark Icon */}
                  <IconButton
            size="small"
            onClick={() => setInfoModalOpen(true)}

          >
            <HelpOutline sx={{ fontSize: '20px', color: mediumMain }} />
          </IconButton>
            </Box>
      </FlexBetween>

      {/* Info Modal */}
      <Dialog
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        disableScrollLock
      >
        <DialogTitle>
          Wettbewerb – Dein eigenes Kunstkaleidoskop hochladen
          <IconButton
            aria-label="close"
            onClick={() => setInfoModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Hier können teilnehmende Schüler bald ihre fertig gestalteten Kunstkaleidoskope als Bilder und Videos hochladen. Mit einem Klick auf den blauen Post-Button wird der Upload-Prozess gestartet. Bitte beachten Sie, dass das Kunstwerk erst dann als offizielles Kunstkaleidoskop gilt, wenn es von uns freigegeben und auf dieser Seite veröffentlicht wurde.
          </Typography>
          <Typography gutterBottom>
            <strong>Für die ersten 100 eingereichten Kunstkaleidoskope</strong> erfolgt der Upload noch per E-Mail, da sich die Seite im Aufbau befindet. Bitte senden Sie die Fotos Ihres gestalteten Rohlings und das Video an: <a href="mailto:info@kaleidoskop-hamburg.de">info@kaleidoskop-hamburg.de</a>.
          </Typography>
          <Typography gutterBottom>
            <strong>Hinweis:</strong> Das Video darf eine Länge von 15 Sekunden nicht überschreiten, da es andernfalls aus rechtlichen Gründen gekürzt werden muss.
          </Typography>
        </DialogContent>
      </Dialog>

      {isImage && (
        <Box
          border={`1px solid ${medium}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem"
        >
          <Dropzone
            acceptedFiles=".jpg,.jpeg,.png"
            multiple={false}
            onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps()}
                border={`2px dashed ${palette.primary.main}`}
                p="1rem"
                width="100%"
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <input {...getInputProps()} />
                {!image ? (
                  <Typography>Bild hier eintragen</Typography>
                ) : (
                  <Box>
                    <img
                      src={URL.createObjectURL(image)}
                      alt="preview"
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                    <FlexBetween mt="0.5rem">
                      <Typography>{image.name}</Typography>
                      <IconButton onClick={() => setImage(null)}>
                        <DeleteOutlined />
                      </IconButton>
                    </FlexBetween>
                  </Box>
                )}
              </Box>
            )}
          </Dropzone>
        </Box>
      )}

      {/* Login Modal */}
      <Modal
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        disableScrollLock
      >
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setLoginModalOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h6" align="center" gutterBottom>
            Sie müssen eingeloggt sein, um Fotos zu posten.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = "/einloggen";
              }}
            >
              Einloggen
            </Button>
          </Box>
        </Box>
      </Modal>
    </WidgetWrapper>
  );
};

export default MyPostWidget;
