// kontakt/index.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Grid,
  FormHelperText,
} from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import { Phone, Email, Business, Language, LocationOn } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';

const KontaktPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:999px)');
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Validation Schema using Yup
  const validationSchema = yup.object().shape({
    vorname: yup.string().required('Vorname ist erforderlich'),
    nachname: yup.string().required('Nachname ist erforderlich'),
    telefonnummer: yup
      .string()
      .required('Telefonnummer ist erforderlich')
      .matches(
        /^\+?[0-9\s\-()]{7,}$/,
        'Bitte geben Sie eine gültige Telefonnummer ein'
      ),
    email: yup.string().email('Ungültige E-Mail-Adresse').required('E-Mail ist erforderlich'),
    unternehmen: yup.string(),
    website: yup.string().url('Bitte geben Sie eine gültige URL ein'),
    unternehmenssitz: yup.string(),
    nachricht: yup.string(),
  });

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      vorname: '',
      nachname: '',
      telefonnummer: '',
      email: '',
      unternehmen: '',
      website: '',
      unternehmenssitz: '',
      nachricht: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Send data to your server or directly to HubSpot API
        await sendToHubSpot(values);
        setFormSubmitted(true);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  // Function to check if company fields should be required
  const areCompanyFieldsRequired = () => {
    const { unternehmen, website, unternehmenssitz } = formik.values;
    return unternehmen || website || unternehmenssitz;
  };

  // Adjust validation schema based on company fields
  React.useEffect(() => {
    if (areCompanyFieldsRequired()) {
      validationSchema.fields.unternehmen = yup
        .string()
        .required('Unternehmen ist erforderlich');
      validationSchema.fields.website = yup
        .string()
        .url('Bitte geben Sie eine gültige URL ein')
        .required('Website ist erforderlich');
      validationSchema.fields.unternehmenssitz = yup
        .string()
        .required('Unternehmenssitz ist erforderlich');
    } else {
      validationSchema.fields.unternehmen = yup.string();
      validationSchema.fields.website = yup.string().url('Bitte geben Sie eine gültige URL ein');
      validationSchema.fields.unternehmenssitz = yup.string();
    }
  }, [formik.values]);

  // Function to send data to HubSpot API
  const sendToHubSpot = async (formData) => {
    try {
      await axios.post('http://localhost:3001/api/hubspot', formData);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };  

  if (formSubmitted) {
    return (
      <Box>
        <Navbar />
        <Box
          sx={{
            padding: isMobile ? '10px' : '20px',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Danke schön! Ihre Einreichung ist eingegangen!
          </Typography>
        </Box>
        <Footer />
      </Box>
    );
  }

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: isMobile ? '10px' : '20px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        {/* Header and Image */}
        <Typography variant="h4" gutterBottom sx={{textAlign: 'center'}}>
          Ihr Ansprechpartner
        </Typography>
        <Box textAlign="center">
          <img
            src="/assets/andreas_metz.webp"
            alt="Andreas Metz"
            style={{ width: '300px', borderRadius: '50%' }}
          />
          <Typography variant="h5" sx={{ marginTop: '10px' }}>
            Andreas Metz
          </Typography>
          <Typography variant="subtitle1">Geschäftsführer</Typography>
        </Box>

        <Typography variant="h5" sx={{ marginTop: '30px', textAlign: 'center' }}>
          Jetzt hier Eintragen
        </Typography>

        {/* Form */}
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6" sx={{ marginTop: '30px' }}>
            Für Privatpersonen
          </Typography>

          <Grid container spacing={2}>
            {/* Vorname */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="vorname"
                name="vorname"
                label="Vorname*"
                value={formik.values.vorname}
                onChange={formik.handleChange}
                error={formik.touched.vorname && Boolean(formik.errors.vorname)}
                helperText={formik.touched.vorname && formik.errors.vorname}
              />
            </Grid>
            {/* Nachname */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="nachname"
                name="nachname"
                label="Nachname*"
                value={formik.values.nachname}
                onChange={formik.handleChange}
                error={formik.touched.nachname && Boolean(formik.errors.nachname)}
                helperText={formik.touched.nachname && formik.errors.nachname}
              />
            </Grid>
            {/* Telefonnummer */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="telefonnummer"
                name="telefonnummer"
                label="Telefonnummer*"
                value={formik.values.telefonnummer}
                onChange={formik.handleChange}
                error={formik.touched.telefonnummer && Boolean(formik.errors.telefonnummer)}
                helperText={formik.touched.telefonnummer && formik.errors.telefonnummer}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-Mail*"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ marginTop: '30px', marginBottom: '10px' }}>
            ...oder tragen Sie Ihr Unternehmen gleich mit ein
          </Typography>

          <Grid container spacing={2}>
            {/* Unternehmen */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="unternehmen"
                name="unternehmen"
                label={`Unternehmen${areCompanyFieldsRequired() ? '*' : ''}`}
                value={formik.values.unternehmen}
                onChange={formik.handleChange}
                error={formik.touched.unternehmen && Boolean(formik.errors.unternehmen)}
                helperText={formik.touched.unternehmen && formik.errors.unternehmen}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Website */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="website"
                name="website"
                label={`Website${areCompanyFieldsRequired() ? '*' : ''}`}
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Language />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Unternehmenssitz */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="unternehmenssitz"
                name="unternehmenssitz"
                label={`Unternehmenssitz${areCompanyFieldsRequired() ? '*' : ''}`}
                value={formik.values.unternehmenssitz}
                onChange={formik.handleChange}
                error={
                  formik.touched.unternehmenssitz && Boolean(formik.errors.unternehmenssitz)
                }
                helperText={
                  formik.touched.unternehmenssitz && formik.errors.unternehmenssitz
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {/* Nachricht */}
          <Box textAlign="center" sx={{ marginTop: '30px' }}>
            <Typography variant="h6">Ihre Nachricht an Uns</Typography>
            <TextField
              id="nachricht"
              name="nachricht"
              multiline
              rows={4}
              variant="outlined"
              value={formik.values.nachricht}
              onChange={formik.handleChange}
              error={formik.touched.nachricht && Boolean(formik.errors.nachricht)}
              helperText={formik.touched.nachricht && formik.errors.nachricht}
              sx={{ width: '100%', maxWidth: '600px', marginTop: '10px' }}
            />
          </Box>

          {/* Submit Button */}
          <Box textAlign="center" sx={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" type="submit">
              Abschicken
            </Button>
          </Box>

          {/* Pflichtfelder Hinweis */}
          <Typography
            variant="subtitle2"
            sx={{ marginTop: '20px', textAlign: 'center' }}
          >
            *Pflichtfelder
          </Typography>
        </form>
      </Box>

      <Footer />
    </Box>
  );
};

export default KontaktPage;
